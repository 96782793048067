.order-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  .order-box {
    padding: 24px;
    background-color: #fff;
    margin: 0 auto;
    width: 420px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    @media (max-width: 420px) {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      overflow: hidden;
      form {
        width: 85%;
      }
    }
    .order-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0;
    }
    label {
      color: #000;
      font-size: 16px;
    }
    button {
      margin-left: auto;
      display: flex;
      width: 100%;
    }
  }
}
.toggle-label {
  .MuiTypography-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    // color: var(--primary-color) !important;
  }
}
.unit-no-field {
  .MuiAutocomplete-inputRoot {
    padding: 0px !important;
  }
}
.divider-link {
  .MuiDivider-root {
    width: 100% !important;
  }
}
.group-title-cell {
  text-align: center;
  vertical-align: middle;
}
.expand-collapse-container {
  position: relative;
  height: 100%; /* Parent height (adjust as needed) */
  width: 50px; /* Adjust width as needed */
}

.vertical-line {
  position: absolute;
  height: 100%; /* Takes height of parent container */
  width: 2px;
  background-color: #e7e7ec; /* Line color */
  left: 50%;
}
.shrink-icon {
  color: rgb(161, 161, 161);
  background-color: #ecf3f9a3;
  border: 1px solid rgb(161, 161, 161);
  font-size: 20px !important;
  padding: 2px;
  border-radius: 2px;
}
.icon-button {
  position: absolute;
  top: 40vh; /* Static distance from the top (adjust as needed) */
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.order-info-sec {
  transition: max-height 1s ease-out;
}

.order-header-logo {
  height: 95px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #dddddd;
  background-color: #fff !important;
}
.order-header-title {
  height: 65px;
  background: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px !important;
  padding: 16px;
  border-radius: 6px;
  box-shadow: -25px -17px 30px 40px rgba(0, 0, 0, 0.031372549);
  position: sticky !important;
  top: 0;
  z-index: 1;
  a {
    text-decoration: none;
  }
  h3 {
    color: #1976d2 !important;
    font-weight: 700;
  }
}
.order-dashboard-sidebar {
  .MuiPaper-root {
    background: var(--sidebar-color);
  }
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.0588235294);
  .order-btn {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 6px;
    margin: 14px;
    color: #1976d2;
    border: 1px solid #1976d2;
    border-radius: 4px;
  }

  span {
    padding-top: 2px;
    color: var(--sidebar-font-color);
    font-size: 12.5px;
    font-weight: 500 !important;
  }
  .sidebar-item {
    border-radius: 10px;
    margin: 5px 0px !important;
    padding: 4px 12px !important;
    svg {
      fill: var(--sidebar-font-color) !important;
    }
  }
  .sidebar-item:hover {
    background: var(--sidebar-lighten-color);
    svg {
      fill: var(--sidebar-font-darker-color) !important;
    }
    span {
      color: var(--sidebar-font-darker-color);
    }
  }
  .selected-item {
    background: var(--sidebar-lighten-color);
    span {
      color: var(--sidebar-font-darker-color) !important;
    }
    svg {
      fill: var(--sidebar-font-darker-color) !important;
    }
  }
}
.order-dashboard-main {
  background: #fff !important;
  display: flex;
  justify-content: space-between;
  margin: 16px !important;
  padding: 16px;
  border-radius: 6px;
  box-shadow: -25px -17px 30px 40px rgba(0, 0, 0, 0.031372549);
  .add-order-content {
    display: block !important;
  }
  .order-error {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    color: #ff1241 !important;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .order-dashboard-content {
    margin-bottom: 16px;
    .transaction-logs-wrapper {
      .user-form {
        label {
          top: -16px !important;
        }
      }
      h4 {
        color: #1976d2;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        padding: 0 12px 0 0;
      }
      .daterange-picker {
        label {
          margin-top: -5px !important;
        }
        span.datepicker-middle {
          font-size: 22px;
          margin: 0 5px;
          color: #bfbfbf;
        }
      }
      button.create-btn {
        border-radius: 5px;
        border: 1px solid #1976d2;
        color: #1976d2;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        span.button-icon {
          margin: 0 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      th {
        background: #ecf3f9;
        color: #49454f;
        font-size: 13px;
        font-weight: 600;
        line-height: 24px;
      }
      td {
        color: #49454f;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        padding: 12px;
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }
      tr:nth-child(even) {
        background: #ecf3f9;
      }
      .pagination-container {
        margin-top: 8px;
      }
    }
  }
}
.place-order-stepper {
  margin-bottom: 28px;
}
.place-order-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  background: var(--primary-color);
  color: #fff;
}
.order-switch-label {
  label {
    min-width: unset !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  span {
    font-size: 14px !important;
    color: var(--primary-color);
    font-weight: 600;
    margin: 0 2px;
  }
}
.disabled-form-setup {
  .Mui-disabled {
    -webkit-text-fill-color: black !important;
  }
}
.address-section {
  padding-top: 36px;
}
.order-form {
  margin-top: 8px;
}
.place-order-stepper {
  .MuiStepLabel-label.Mui-active {
    color: #1976d2 !important;
    font-weight: 700;
    font-size: 14px;
  }
}

.order-detail-tab {
  .MuiTab-root {
    font-size: 13.5px !important;
    min-height: unset !important;
    font-weight: 550;
  }
}

.order-history {
  .MuiTab-root {
    font-size: 13px !important;
    min-height: unset !important;
    font-weight: 550;
  }
}

.order-form-checkbox {
  .MuiFormControlLabel-label {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
}
.order-form-divider {
  hr {
    background-color: unset !important;
  }
}
.order-form {
  // .MuiAccordionSummary-root {
  //   min-height: 40px !important;
  // }
  // .MuiAccordionDetails-root {
  //   padding: 8px !important;
  // }
  label {
    margin-top: -8px !important;
    font-weight: 500 !important;
  }
  .MuiInputLabel-shrink {
    margin-top: -2px !important;
  }
  .form-title {
    h4 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 0 !important;
      margin-top: 10px;
    }
    span {
      font-size: 14px;
    }
    margin-bottom: 0px;
  }
  .sub-title {
    margin-bottom: 20px !important;
  }
}
.MuiButtonBase-root .MuiAccordionSummary-root .Mui-expanded {
  min-height: 44px !important;
}
.acc-title {
  padding: 2px 14px;
  background: white;
  border-radius: 4px;
}
.participant-block {
  border: 2px solid #eee;
  position: relative;
  margin: 20px;
  padding: 5px 16px 10px 8px;
  .remove-participant-btn {
    position: absolute;
    top: -15px;
    right: -30px;
    border: none;
    padding: 0 !important;
    button {
      border: none;
      padding: 0;
    }
  }
}
.next-btn {
  button {
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    margin: 0 4px;
    padding: 6px 10px;
  }
}
.next-btn button:hover {
  color: var(--primary-color) !important;
  background: #fff !important;
}
.order-success-msg {
  margin: 20px 20px 20px 8px !important;
  font-weight: 600 !important;
}
.open-link:hover {
  background: #e4e4e9 !important;
}
.order-detail-header {
  background-color: var(--primary-color);
  color: #fff;
  .close-btn {
    width: 99%;
  }
  svg {
    color: #eee !important;
    margin-top: 12px;
  }
  h2 {
    padding-top: 0 !important;
    font-size: 28px !important;
  }
  span {
    font-size: 16px;
    padding: 24px;
  }
  .order-detail-tab {
    margin-top: 20px;
    padding-left: 8px;
  }
  button.MuiTab-root {
    color: #a3babe !important;
  }
  button.Mui-selected {
    color: #fff !important;
    z-index: 999;
  }
}
.order-detail-form {
  min-height: 300px;
  h5 {
    font-size: 22px;
    padding: 10px 0;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .footer-submit-btn {
    padding: 30px 0;
  }
}
.order-radio-btn .MuiFormGroup-root {
  display: block;
  label {
    display: contents;
    margin-right: 4px;
  }
}
.common-cancel-dialog {
  .MuiDialogContent-root {
    padding: 18px !important;
    font-size: 16px;
  }
  .confirm-btn {
    background-color: #fff;
    color: var(--primary-color);
  }
  .next-btn {
    margin-bottom: 16px;
  }
}
.relative {
  position: relative;
}
.address-list {
  position: absolute;
  top: 55px;
  left: 18px;
  background: #fff;
  z-index: 999;
  width: 96%;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.place-order-dialog {
  .MuiDialog-paper {
    width: 100%;
  }
}
.checkbox-container {
  label {
    display: inline-flex !important;
  }
}
.copy-order-invite {
  .MuiChip-root {
    border: none !important;
  }
  svg {
    width: 18px;
  }
  span {
    color: var(--primary-color) !important;
  }
}
.resend-invite {
  .MuiChip-root {
    border: none !important;
  }

  span {
    color: var(--primary-color) !important;
  }
}
.order-invite-label {
  margin: 10px 0 !important;
  padding-top: 10px !important;
  color: #000 !important;
  font-weight: 600 !important;
}
.order-detail-desc {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 12px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.final-success-alert {
  .MuiAlert-icon {
    font-size: 60px !important;
  }
  height: 300px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stepper-order-form {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
}

.order-form-radio {
  .MuiFormControlLabel-label {
    font-size: 13px !important;
    font-weight: 550 !important;
  }
}

.react-datepicker-popper {
  z-index: 1111 !important;
}

.creat-order-btn .Mui-disabled {
  -webkit-text-fill-color: white !important;
  color: white !important;
  cursor: not-allowed !important;
  background-color: #c2bcbc;
  border: none !important;
}
@media (width < 768px) {
  .order-dashboard-main {
    margin: 5px !important;
    padding: 0px;
  }
  .order-detail-header span {
    padding: 8px;
    font-size: 14px !important;
    display: flex;
    justify-content: start;
  }
  .order-detail-header h2 {
    padding: 8px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
  }
  .order-detail-header .order-detail-tab {
    margin-top: 5px !important;
  }
}

@media (width < 400px) {
  .order-form {
    padding: 10px !important;
    margin-top: 0px !important;
  }
  .order-detail-header h2 {
    font-size: 18px !important;
  }
  .order-detail-header span {
    font-size: 13.5px !important;
  }
  .order-switch-label span {
    font-size: 12px !important;
  }
  .final-success-alert {
    .MuiAlert-icon {
      font-size: 50px !important;
    }
    height: 300px;
    width: 280px;
  }
}

@media (width < 525px) {
  .order-form {
    padding: 10px !important;
    margin-top: 0px !important;
  }
}
