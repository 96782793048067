.m-0 {
  margin: 0px !important;
}

.m-2 {
  margin: 2px;
}
.h-100{
  height: 100% !important;
}
.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px;
}

.m-16 {
  margin: 16px;
}

.m-20 {
  margin: 20px !important;
}

// padding
.p-0 {
  padding: 0px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-4 {
  padding: 4px !important;
}
.p-6 {
  padding: 6px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px;
}
.ps-24 {
  padding: 0px 24px;
}

// Margin Top

.mt-minus-2{
  margin-top: -2px !important;
}
.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}
.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

// Padding Top
.pt-0 {
  padding-top: 0px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-12 {
  padding-top: 12px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-20 {
  padding-top: 20px !important;
}

// Margin Right
.mr-0 {
  margin-right: 0px;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px;
}

// Padding Right
.pr-0 {
  margin-right: 0px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-8 {
  padding-right: 8px;
}

.pr-12 {
  padding-right: 12px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-20 {
  padding-right: 20px;
}

// Margin Bottom
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

// Padding Bottom
.pb-0 {
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 4px !important;
} 

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

// Margin Left
.ml-0 {
  margin-left: 0px;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}
// Padding Left
.pl-0 {
  padding-left: 0px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-6 {
  padding-left: 6px !important;
}
.pl-8 {
  padding-left: 8px;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px;
}

.pl-20 {
  padding-left: 20px;
}

.plt-8 {
  padding-left: 8px;
  padding-top: 8px;
}

.plt-16 {
  padding-left: 16px;
  padding-top: 16px;
}

// border

.bl{
  border-left : 1px solid #d3cccc
}
.bt{
  border-top : 1px solid #d3cccc
}
.bb{
  border-bottom : 1px solid #d3cccc
}
.br{
  border-right : 1px solid #d3cccc
}
// Margin left-right
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

// Margin top-bottom
.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

// Padding left-right
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

// padding top-bottom
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

// Display Properties
.d-flex {
  display: flex !important;
}

.d-none {
  display: none;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end;
}

.align-self-stretch {
  align-self: stretch;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-imp {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-imp {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-end-imp {
  justify-content: end !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1-0-auto {
  flex: 1 0 auto;
}

// Text
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-bolder {
  font-weight: bolder;
}

.text-lighter {
  font-weight: lighter;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-450 {
  font-weight: 450 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-550 {
  font-weight: 550 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-650 {
  font-weight: 650 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}
.logo-size {
  height: auto;
  width: 160px;
}
.svg-size {
  height: auto;
  width: 300px;
}
.success {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 1px;
}
.text-large {
  font-size: large;
}
.f-18 {
  font-size: 18px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-15 {
  font-size: 15px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-13 {
  font-size: 13px !important;
}
.f-12 {
  font-size: 12px !important;
}

.f-13-5 {
  font-size: 13.5px !important;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-capitalize {
  text-transform: capitalize;
}

// Width properties
.w-100p {
  width: 100% !important;
}
.w-min-content {
  width: min-content;
}
.w-max-content {
  width: max-content;
}
.radius-5 {
  border-radius: 5px;
}
.input-400 {
  width: 400px !important;
}
.min-width-100p {
  min-width: 100%;
}
.min-width-110 {
  min-width: 110px !important;
}
.min-width-300 {
  min-width: 300px !important;
}
.min-width-350 {
  min-width: 350px !important;
}
.min-width-400 {
  min-width: 400px !important;
}
.align-items-center {
  align-items: center !important;
}

// Height properties
.h-200p {
  height: 200px;
}

// Utils
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-none {
  cursor: context-menu;
}
.overflow-hidden {
  overflow: hidden;
}

// Positions
.sticky-left {
  position: sticky;
  left: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
}
.sticky-bottom {
  position: sticky;
  bottom: 0;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

// Z Indexes
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-10 {
  z-index: 10;
}
.z-100 {
  z-index: 100;
}

.float-left {
  float: left;
}

.left-0 {
  left: 0;
}

.float-right {
  float: right;
}

.right-0 {
  right: 0;
}

ol.custom-order-stage {
  list-style: none;
}

ol.custom-order-stage > li:before {
  content: attr(part) ' - ';
}

.image-scale-down {
  object-fit: scale-down;
}

.hidden-label .ant-form-item-label > label {
  display: none !important;
}

.width-inherit {
  width: inherit !important;
}

.height-inherit {
  height: inherit !important;
}

.spanner {
  position: fixed;
  top: 50%;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  //   background: #2a2a2a55;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  color: black;
  transform: translateY(-50%);
  z-index: 1060;
  visibility: hidden;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}
.table-select-filter {
  .MuiSvgIcon-root {
    right: 1px !important;
    background: white !important;
  }
}
.loading-spinner {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 100%; /* Set the width to 100% */
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto; /* Center the loader horizontally within the container */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.show {
  visibility: visible;
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  height: 70px; /* Adjust based on the height of your fixed div */
  background: white; /* Optional: To ensure visibility over content */
  z-index: 10; /* To ensure it stays above other elements */
}

.droppable-container {
  overflow-y: scroll;
  padding-bottom: 70px; /* Matches the height of the fixed-footer */
}
.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.spanner.show,
.overlay.show {
  opacity: 1;
}
.DayPicker_weekHeader_ul {
  display: none;
}
.mul-seller-radio{
  label{
    min-width: unset !important;
  }
  .css-1jaw3da{
    display: inline-flex !important;
  }
}
.error {
  color: #ff1241;
}

.color-w {
  color: #ff9900 !important;
}

.color-s {
  color: green !important;
}

.color-p {
  color: var(--primary-color) !important;
}
.bg-p {
  background: var(--primary-color) !important;
}

.bg-r {
  background: #f24e44 !important;
}
.color-g {
  color: grey !important;
}
.color-b {
  color: black !important;
}
.border-error {
  border: 1px solid #f24e44 !important;
}
.border-none {
  border: none !important;
}

.border-normal {
  border: 1px solid #1976d2 !important;
}

.icon-btn {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}

.disabled-btn {
  background-color: grey !important;
  cursor: not-allowed !important;
}
.b-color {
  border-color: red !important;
}
.primary-radio {
  label {
    min-width: unset !important;
  }
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      font-size: 13px !important;
      font-weight: 550 !important;
    }
  }
}
.dropzone {
  min-height: auto;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #1976d2;

  border-radius: 10px !important;
  &.drag-active {
    border: 1px solid #1976d2 !important;
    background-color: rgb(25 118 210 / 10%);
  }
  .dz-message {
    margin: 0;
    display: flex;
  }

  .dz-preview {
    border-radius: 10px !important;
    margin: 0.75rem;

    .dz-image {
      border-radius: 10px !important;
      z-index: 1;
    }

    &.dz-file-preview {
      .dz-image {
        background: grey;
      }
    }
  }

  .dz-success-mark,
  .dz-error-mark {
    $size: 40px;

    margin-left: -(divide($size, 2)) !important;
    margin-top: -(divide($size, 2)) !important;

    svg {
      height: $size !important;
      width: $size !important;
    }
  }

  .dz-remove {
    $size: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $size;
    width: $size;
    font-size: 1rem;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    z-index: 2;

    border-radius: 100%;
    top: -(divide($size, 2));
    right: -(divide($size, 2));

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      content: '';
      mask-size: 40%;
      -webkit-mask-size: 40%;
    }

    &:hover {
      &:after {
      }
    }
  }

  .dz-error-message {
    color: var(--kt-danger-inverse);
    background: var(--kt-danger);
  }
}

// Queue upload
.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;

  .dz-message {
    display: none;
  }

  .dropzone-panel {
    .dropzone-upload,
    .dropzone-remove-all {
      display: none;
    }
  }

  .dropzone-item {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    padding: 0.5rem 1rem;
    background-color: grey;

    .dropzone-file {
      flex-grow: 1;

      .dropzone-filename {
        font-size: 0.9rem;
        font-weight: 500;
        color: grey;
        text-overflow: ellipsis;
        margin-right: 0.5rem;

        &:hover {
          color: blue;
        }
      }

      .dropzone-error {
        margin-top: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: red;
        text-overflow: ellipsis;
      }
    }

    .dropzone-progress {
      width: 15%;

      .progress {
        height: 5px;
      }
    }
  }
}
.css-1sf3xto {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.file-preview {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 4px;
  // border: 1px solid #efeaea;
  border-radius: 4px;

  .file-icon svg {
    color: #5e6278;
    width: 24px;
    height: 24px;
  }

  .file-details {
    margin-left: 12px;
    margin-right: 0;
    min-height: 32px;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1 0 0;
    color: #403f3f;
    font-size: 13px;

    font-weight: 600;
    small {
      font-size: 11px;
    }
  }

  .file-close button {
    color: grey !important;
    background-color: transparent !important;
    border: none;
    padding: 0px 10px !important;
    min-width: inherit;
    &:focus,
    &:active {
      border: none;
      box-shadow: none !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

.last-item {
  position: absolute !important;
  bottom: 0 !important;
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  display: flex !important;
  align-items: center !important;
}

.css-1f8bwsm {
  min-width: 40px !important;
}

.sub-header {
  padding: 16px 16px 0 16px;
}

.action-menu-item {
  font-size: 16px !important;
  margin-right: 4px;
}

.menu-actions {
  .MuiMenuItem-root {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
}

.MuiMenu-root {
  ul {
    padding-bottom: 8px !important;
  }
}
.filter-text {
  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 12.5px !important;
  }
  .MuiInputBase-root {
    padding-right: 0px !important;
    font-size: 13px !important;
    // height: 35px !important;
    width: 260px !important;
  }
  .MuiSvgIcon-root {
    font-size: 18px !important;
  }
}
.user-profile-desc {
  background: #dae5ee;
  padding: 50px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-profile-widget {
  // padding: 60px 0;
  text-align: -webkit-center;
  // position: relative;
}
.profile-drawer-main .MuiList-padding {
  padding: 0 !important;
}
iframe {
  background: 'grey';
  border-radius: 6px;
  border: 0px;
}
.mui-tag {
  height: 23px !important;
  padding: 0px !important;
}

.MuiChip-labelMedium {
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-size: 12px !important;
  color: white !important;
}

.MuiStepLabel-label {
  font-size: 12px !important;
  font-weight: 550 !important;
}

.profile-chip {
  .MuiChip-labelMedium {
    color: var(--header-font-color) !important;
    margin: 0px 4px;
    font-weight: 500;
  }
  .MuiChip-root {
    padding: 14px 2px;
  }
}

.user-name {
  .MuiChip-labelMedium {
    padding-left: 2px !important;
  }
}
.icon-db {
  height: 35px;
  margin-left: 5px;
  justify-content: center;
  width: 35px;
  border-radius: 50%;
  background: var(--primary-color);
}

.login-label {
  font-size: 13px !important;
  font-weight: 600;
  font-family: Inter, Roboto, 'Helvetica Neue', Arial, sans-serif !important;
}
.required {
  color: red !important;
  margin-left: 3px !important;
}
.string-value {
  word-break: break-word !important;
  display: block !important;
}
.b-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.list-item-wrapper {
  max-height: 90vh;
  overflow-y: scroll;
  padding: 6px;
}
.highlighted-row {
  background: #d3e4f3;
}
.table {
  width: 95%;
  display: block;
  overflow-x: auto;
}

.table-div {
  width: 100%;
  overflow: scroll;
}
.reset-pwd {
  span {
    font-size: 13px;
  }
}
.user-form {
  .MuiPaper-root {
    .MuiList-root {
      .MuiMenuItem-root {
        font-size: 13px !important;
      }
    }
  }
}
.branding-toggle {
  label {
    margin-top: 0px !important;
  }
}
.MuiSelect-select {
  align-items: center !important;
  display: flex !important;

  li {
    font-size: 13px !important;
  }
}
.profile-item {
  span {
    margin-top: 1px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.css-194r8t5-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 14px !important;
}
.css-17v6y5 {
  font-size: 14px !important;
}

.Toastify__toast-body > div:last-child {
  line-height: 1.5 !important;
}

.pointer-event-none {
  pointer-events: none !important;
}
.no-record {
  color: grey !important;
  font-weight: 500 !important;
  padding: 20px 8px !important;
  text-align: center !important;
}

.note-container {
  padding: 4px;
  margin-top: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px;
}

.border-b {
  border-bottom: 1px solid grey;
}
.date-container {
  width: 500px !important;
}
.source-drawer {
  width: 700px !important;
}
.doc-model {
  .MuiPaper-root {
    max-width: 2000px !important;
  }
}
th {
  padding: 8px !important;
}

.participant-invite-consent {
  .css-1jaw3da {
    display: inline-flex !important;
  }
  label {
    font-size: 13.5px !important;
    font-weight: 650 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
.concent-form-checkbox {
  span.MuiFormControlLabel-label {
    font-weight: 550 !important;
    text-align: justify !important;
  }
}
.button-bottom {
  width: 85% !important;
  position: absolute;
  bottom: 0;
  background-color: white !important;
}
#react-doc-viewer {
  #header-bar {
    display: none !important;
  }
  #proxy-renderer {
    #pdf-download {
      display: none;
    }
  }
}

.side-steps {
  .MuiStepConnector-line {
    min-height: 8px !important;
  }
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 250px !important;
}

.css-pwxzbm {
  max-height: 250px !important;
}

.table-div {
  body ::-webkit-scrollbar {
    height: 5px !important;
  }
}
@media (width < 768px) {
  .bread-title {
    font-size: 14px !important;
  }
  .list-item-wrapper {
    padding: 0px;
  }
}
.MuiPopper-root {
  z-index: 111111 !important;
}
@media (width < 600px) {
  .moby-payload {
    width: 70vw !important;
  }
  .success {
    font-size: 28px;
  }
  .file-preview {
    width: 75vw !important;
  }
  .file-details {
    width: 50vw !important;
  }
  .date-container {
    width: 300px !important;
  }
  .stepper-order-form {
    .MuiStepLabel-label {
      display: none !important;
    }
  }
}

@media (width < 420px) {
  .moby-payload {
    width: 80vw !important;
  }
}

@media (width < 330px) {
  .date-container {
    width: 250px !important;
  }
  .MuiInputBase-root,
  .MuiOutlinedInput-root {
    height: 34px !important;
  }
  .MuiTabs-root {
    width: 275px !important;
    overflow: auto !important;
  }
}
.stripe-card-elem {
  border: 1px solid #e1d8d8;
  padding: 18px;
  border-radius: 5px;
}
