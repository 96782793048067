.admin-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  .admin-box {
    padding: 24px;
    background-color: #fff;
    margin: 0 auto;
    width: 420px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    @media (max-width: 420px) {
      margin-left: 0;
      margin-right: 0;
      padding: 10px;
      overflow: hidden;
      form {
        width: 85%;
      }
    }
    .admin-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0;
    }
    .welcome-heading {
      h4 {
        font-weight: 700;
        margin-bottom: 0 !important;
        font-size: 24px;
        display: flex;
      }
    }
    label {
      color: #000;
      font-size: 16px;
    }
    button {
      margin-left: auto;
      display: flex;
      width: 100%;
    }
  }
}
.popover-btn {
  height: 25px;
  font-size: 12px !important;
}
.show-checkbox {
  .MuiButtonBase-root {
    padding: 4px 8px !important;
  }
}
.upload-container {
  padding: 8px;
  border: 1px solid #005fd429;
  border-radius: 8px;
  margin: 20px 0px;
}
.group-title {
  vertical-align: middle; 
  td {
    .MuiDivider-root span{
      font-size: 13.5px !important; 
      color: #5a5252 !important;
    }
    font-weight: 500 !important; /* Make the text bold */
    text-align: center !important;
    color: #005fd4 !important;
    padding: 12px 0 !important; /* Add padding for better spacing */
  }
}
.permission-info {
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 16px;
    .title-text {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  ul {
    margin-top: 0px;
    padding-inline-start: 15px;
    margin-bottom: 0;
    li {
      display: unset !important;
      margin-bottom: 0px;
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 500;
        color: gray;
        .permission-name {
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }
  }
}
.office-label {
  .MuiTypography-root {
    color: black !important;
    font-size: 13px !important;
    font-weight: 550 !important;
  }
}
.report-filter {
  // .react-datepicker-wrapper {
  //   width: unset !important;
  // }
}
.office-select {
  height: 30px !important;
  .MuiSelect-select {
    height: unset !important;
  }
  label {
    margin-top: 0px !important;
  }
  .MuiInputBase-root {
    height: 40px !important;
  }
}
.office-checkbox {
  .MuiTypography-root {
    color: black !important;
    font-size: 13px !important;
    font-weight: 550 !important;
  }
}

.profile-chip {
  .MuiChip-root {
    border-color: var(--header-font-color) !important;
  }
}
.office-block {
  border: 1px solid #eee;
  position: relative;
  padding: 5px 16px 10px 8px;
  border-radius: 4px;
  .remove-participant-btn {
    position: absolute;
    top: -15px;
    right: -30px;
    border: none;
    padding: 0 !important;
    button {
      border: none;
      padding: 0;
    }
  }
}
.MuiList-root {
  .MuiButtonBase-root {
    font-size: 14px !important;
  }
}
.bread-crumb-btn {
  color: var(--header-font-color) !important;
}
.bread-title {
  color: var(--header-font-color) !important;
}
.participant-input-field {
  .MuiInputBase-input {
    height: 30px !important;
  }
}
.logo-input {
  label {
    top: 0px !important;
    margin-top: 0px !important;
  }
}

.DateInput_input {
  font-family: Inter, Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 12.5px !important;
  font-weight: 400;
  color: #000;
  padding: 4px 8px !important;
}
.DateInput {
  width: 5vw !important;
}
.DayPicker_transitionContainer__horizontal {
  min-height: 350px !important;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: none !important;
}
.DateRangePickerInput_clearDates__hide {
  visibility: visible !important;
}
.DateRangePickerInput_clearDates {
  padding: 0px !important;
  margin: 0 5px 0 5px !important;
  .DateRangePickerInput_clearDates_svg {
    height: 8px !important;
  }
}
.DateRangePickerInput_arrow_svg {
  height: 16px !important;
  width: 16px !important;
}
.DateRangePickerInput__withBorder {
  border: 1px solid #d0cdcd !important;
}
.react-daterange-picker__calendar-button {
  display: none !important;
}
.react-daterange-picker__button {
  padding: 0px !important;
}
.react-daterange-picker__button svg {
  height: 12px !important;
  color: grey !important;
}
.react-daterange-picker__inputGroup {
  margin-left: 8px !important;
  padding: 0px !important;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: none !important;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid #d4c7c7;
  height: 35px;
  border-radius: 4px;
  font-size: 13px;
  font-family: Inter, Roboto, 'Helvetica Neue', Arial, sans-serif;
}
.filter-select {
  .MuiInputBase-input.MuiOutlinedInput-input {
    height: 35px !important;
  }
}
.filter-field {
  .MuiInputBase-input::placeholder {
    color: #000 !important;
  }
}
.status-parent {
  position: relative;
  border: 1px solid rgba(50, 50, 93, 0.25);
}
.status-child {
  position: absolute;
  top: -10px;
  background-color: white;
}
.react-daterange-picker__wrapper {
}
.report-filter {
  .MuiInputBase-root {
    // border-radius: 0 !important;
  }
  // border: 1px solid #e5e1e1;
  // border-radius: 5px;
  // padding: 10px;
}
.header-logo {
  height: 95px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #dddddd;
  background-color: #fff !important;
}
.header-title {
  height: 65px;
  background: var(--header-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px !important;
  padding: 16px 16px 16px 0px;
  border-radius: 6px;
  box-shadow: -25px -17px 30px 40px rgba(0, 0, 0, 0.031372549);
  a {
    text-decoration: none;
  }
  h3 {
    color: #1976d2 !important;
    font-weight: 600;
    font-size: 20px;
    span {
      color: #777777;
    }
  }
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 34px !important;
}
h2#customized-dialog-title {
  font-weight: 600;
  font-size: 18px;
}
.sidebar-item-drawer {
  padding: 10px 20px !important;
}
.dashboard-sidebar {
  .MuiPaper-root {
    background: var(--sidebar-color);
    height: unset !important
  }
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.0588235294);
  span {
    padding-top: 2px;
    color: var(--sidebar-font-color);
    font-size: 12.5px;
    font-weight: 500 !important;
    // font-family: 'Nunito Sans', sans-serif;
  }
  .sidebar-item {
    border-radius: 10px;
    margin: 0px !important;
    padding: 4px 7px !important;
    svg {
      fill: var(--sidebar-font-color) !important;
    }
  }
  .sidebar-item:hover {
    background: var(--sidebar-lighten-color);
    svg {
      fill: var(--sidebar-font-darker-color) !important;
    }
    span {
      color: var(--sidebar-font-darker-color);
    }
  }
  .selected-item {
    background: var(--sidebar-lighten-color);
    span {
      color: var(--sidebar-font-darker-color) !important;
    }
    svg {
      fill: var(--sidebar-font-darker-color) !important;
    }
  }
}
.completed-bg {
  background-color: #2e7d32 !important;
}

.drawer-form {
  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    background: white !important;
    font-size: 13px !important;
  }
}
.office-table {
  th {
    display: none !important;
  }
}
.offices {
  display: flex;
  justify-content: space-between;
  margin: 16px !important;
  padding: 16px;
  border-radius: 6px;
  min-height: 93vh;
  box-shadow: -25px -17px 30px 40px rgba(0, 0, 0, 0.031372549);
  background-color: white;
}
.dashboard-main {
  background: var(--bg-color) !important;
  display: flex;
  justify-content: space-between;
  margin: 16px !important;
  padding: 16px;
  border-radius: 6px;
  min-height: 93vh;
  box-shadow: -25px -17px 30px 40px rgba(0, 0, 0, 0.031372549);
  .dashboard-content {
    margin-bottom: 16px;
    .transaction-logs-wrapper {
      .user-form {
        label {
          top: -16px !important;
        }
      }
      h4 {
        color: var(--primary-color);
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        padding: 0 12px 0 0;
      }
      .template-section {
        padding: 0 14px;
        .table-inner-section {
          width: 100%;
          overflow: scroll;
        }
      }
      .section-tab {
        .MuiButtonBase-root {
          color: black;
        }
        .MuiTabs-indicator {
          background-color: rgb(161, 160, 160) !important;
          height: 0px !important;
          // display: none !important;
        }
        .MuiTab-root {
          padding: 10px 16px;
          font-weight: 420;
          min-height: 30px !important;
          font-size: 13px !important;
          color: #5f5b5b !important;
        }
        .Mui-selected {
          color: black !important;
          border-radius: 8px;
        }
        .Mui-disabled {
          opacity: unset !important;
        }
      }
      .section-tabs-list {
        .MuiTabs-scroller {
          display: flex;
          align-items: center;
        }
        .MuiTabs-root {
          background: #dae5ee !important;
          border-radius: 10px;
          border-bottom: none !important;
        }
      }
      .group-tabs-list {
        .MuiTabs-scroller {
          display: flex;
          align-items: center;
        }
        .MuiTabs-root {
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 3px;
        }
      }
      .group-tab {
        .MuiTabs-indicator {
          display: none !important;
        }
        .MuiTab-root {
          color: #968d8d !important;
          padding: 10px 16px;
          min-height: 30px !important;
          font-size: 13px !important;
        }
        .Mui-selected {
          border-radius: 8px;
          color: black !important;
        }
        .Mui-disabled {
          opacity: unset !important;
        }
      }
      .daterange-picker {
        label {
          margin-top: -5px !important;
        }
        span.datepicker-middle {
          font-size: 22px;
          margin: 0 5px;
          color: #bfbfbf;
        }
      }
      button.create-btn {
        border-radius: 5px;
        border: 1px solid #1976d2;
        color: #1976d2;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        span.button-icon {
          margin: 0 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      th {
        background: #dae5ee;
        color: #49454f;
        font-size: 13px;
        font-weight: 500;
        // line-height: 24px;
      }
      td {
        color: #49454f;
        border-bottom: none;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px;
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }
      tr:nth-child(even) {
        background: #ecf3f9;
      }
      .pagination-container {
        margin-top: 8px;
      }
    }
  }
}

.template-section {
  td {
    padding: 5px !important;
  }
  tr:nth-child(even) {
    background: #fff !important;
  }
}
.section-btn {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  position: absolute !important;
  right: 10px !important;
}
.updated-field {
  .MuiInputBase-root {
    color: #1976d2 !important;
  }
}
.client-user-form {
  margin-top: 0 !important;
  label {
    font-size: 13px !important;
  }
  .participant-input-field {
    .MuiInputBase-input.MuiOutlinedInput-input,
    .MuiInputBase-input.MuiOutlinedInput-input {
      height: 30px !important;
    }
  }
  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 13px;
    height: 35px !important;
    box-sizing: border-box;
    background-color: white !important;
    border: none;
  }
  .required {
    color: red !important;
  }
}

.question-section-container {
  padding: 8px 20px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.section-title-label {
  margin: 5px 0 5px 0;
  color: #1976d2;
  font-size: 14px;
  font-weight: 600;
}

.participant-title-label {
  margin: 0 0 10px 0;
  color: #1976d2;
  font-size: 14px !important;
  font-weight: 600;
}
.form-field-container {
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
    font-size: 13px !important;
  }
  .css-1uvydh2.Mui-disabled {
    -webkit-text-fill-color: black !important;
    font-size: 13px !important;
  }
  .Mui-disabled {
    -webkit-text-fill-color: black !important;
    font-size: 13px !important;
  }
}
.text-field-container {
  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    background-color: white;
  }
}
.file-label {
  color: #3a8352;
  color: #808080;
  font-size: 13px;
  font-weight: 600;
  padding: 4px 10px;
}
.dashboard-container {
  background: var(--bg-color);
  // display: flex;
  // justify-content: space-between;

  // padding: 16px;
  border-radius: 6px;
  // min-height: 90vh;
  // width: 35%;
  height: 45vh;
}

.dashboard-heading {
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #534e4e;
}
.address-field {
  .MuiFormHelperText-root {
    color: red !important;
  }
}
.reporting-filters-main {
  td {
    border: 1px solid #e4e7e7 !important;
  }
}
.tab-title {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  .MuiChip-root {
    border: none !important;
    padding: 5px !important;
  }
  .MuiChip-labelMedium {
    color: grey !important ;
    font-size: 13.5px !important;
  }
}
.btn-edit {
  .MuiButtonBase-root {
    line-height: 1.3 !important;
  }
}

.verification-field {
  .MuiButtonBase-root {
    color: green !important;
  }
  .Mui-disabled {
    color: rgb(101, 139, 101) !important;
  }
}

.source-details-modal {
  .Mui-disabled {
    -webkit-text-fill-color: grey !important;
    cursor: not-allowed !important;
  }
}

.MuiDivider-root {
  span {
    font-size: 13px;
    font-weight: 500;
    color: grey;
  }
}
.dashboard-sidebar {
  .MuiListItem-root {
    width: 96% !important;
  }
}
.participant-list {
  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 13px;
    height: 35px !important;
    box-sizing: border-box;
    background-color: white !important;
    border: none;
  }
}

.timeline {
  .MuiTimelineItem-root {
    display: -webkit-box !important;
  }
  .MuiTypography-root {
    margin: 0px !important;
  }
}

.view-client {
  label {
    display: block;
    width: 100%;
    background: #ededf4;
    padding: 6px 8px;
    border-radius: 5px;
  }
}
@media (width < 768px) {
  .client-user-form .MuiInputBase-input.MuiOutlinedInput-input,
  .client-user-form .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 13px !important;
    height: 34px !important;
  }
  .dashboard-main .dashboard-content .transaction-logs-wrapper h4 {
    font-size: 16px;
  }

  .client-user-form label {
    font-size: 12px !important;
  }
  button .btn-section {
    font-size: 13px !important;
    padding: 3px 8px !important;
  }

  .header-title {
    margin: 5px !important;
    height: 55px;
    padding: 10px;
  }
  .dashboard-main {
    margin: 5px !important;
    padding: 0px;
  }
}
